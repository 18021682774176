<template>
  <div class="index">
    <div class="content-main">
      <park-header></park-header>
      <!--<div class="index-title">我要</div>-->
      <div class="index-content-wrap">
        <div class="index-item"
             v-for="menu in menus"
             v-if="menu.can.includes(userInfo.userTypeName)"
        @click="go2Path(menu)">
          <div class="index-item-icon">
            <img :src="menu.img" alt="">
          </div>
          <div class="index-item-txt">{{menu.txt}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import parkHeader from '../public/park-header'
import * as common from '../api/common'
import * as api from '../api/api'
import { mapState } from 'vuex'
// import * as mqtt from '../api/wsconnect.js';
export default {
  name: 'index',
  components: {
    parkHeader: parkHeader
  },
  data () {
    return {
      menus: []
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    go2Path (menu) {
      if (!menu.released) {
        this.$toast('此功能暂不开放')
        return
      }
      if (!menu.path) {
        this.$toast('请先设置路径')
        return
      }
      console.log('menu.path', menu.path)
      this.$router.push({
        path: menu.path,
        query: {
          ...api.getQuery.apply(this)
        }
      })
    },
    async getUserMenu () {
      let para = {}
      let keys = {
        '/add_vh': 'fixed_management',
        '/add_black': 'blacklist_management',
        '/remote_open_gate': 'remote_open',
        '/inout_record': 'in_out',
        '/recharge_record': 'fee_record_query',
      }
      try {
        if(this.userInfo && this.userInfo.userTypeName == common.userTypesTxt.groupCompany) {
          let res = await api.axiosApiRequests(api.apiUrlList.platform.user.get_user_menu, para)
          if (res && res.code == common.resCode.OK){
            for (let j = 0; j < common.menus.length; j++) {
              let key = keys[common.menus[j].path]
              console.log('key', key)
              if (key) {
                let hasMenu
                for (let i = 0; i < res.menus.length; i++) {
                  console.log('res.menus[i].path_name', res.menus[i].path_name)
                  if (res.menus[i].path_name == key) {
                    hasMenu = true
                    break
                  }
                }
                if (!hasMenu) {
                  let idx = common.menus[j].can.indexOf(common.userTypesTxt.groupCompany)
                  if (idx != -1) common.menus[j].can.splice(idx, 1)
                }
              }
            }
          }
        }
        this.menus = common.menus
      } catch (e) {
        throw e
      }
    },
  },
  mounted () {
    console.log('mounted')
    this.getUserMenu().catch(e => console.log(e))
    // this.$store.commit('commitUserInfo')
  }
}
</script>

<style scoped lang="scss">
@import "../theme/index";
.index {
  @extend .w-100-h-100;
  /*<!--background-color: $global-theme-bg-blue;-->*/
  background-color: $global-bg-color;
  .content-main{
    @extend .content-wrap-gap;
    .index-title{
      padding: 3rem;
      text-align: left;
      font-size: $global-font-size-large;
      font-weight: 900;
      border-bottom: $global-color solid .2rem;
    }
    .index-content-wrap {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      min-height: 30rem;
      margin: 1rem;
      padding-top: 2rem;
      display: flex;
      flex-wrap: wrap;
      background-color: white;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      border-radius: 1rem;
      .index-item{
        flex-shrink: 0;
        margin-top: 1rem;
        text-align: center;
        width: 22%;
        margin-right: 4%;
        /*background-color: blue;*/
        .index-item-icon{
          margin: 0 auto;
          width: 5rem;
          height: 5rem;
          /*<!--border: $global-color solid .2rem;-->*/
          border-radius: 50%;
          text-align: center;
          overflow: hidden;
          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }
      & > .index-item:nth-child(4n) {
        margin-right: 0;
      }
      .index-item-txt {
        text-align: center;
        padding: 1rem 0;
        font-size: $global-font-size-normal;
        font-weight: 900;
      }
    }
  }
}
</style>
